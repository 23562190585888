// PS_SaveDocument
import _forEach from 'lodash/forEach';
import _has from 'lodash/has';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import config from '@/config';
import LzString from 'lz-string';
import PS_BuildHtml from '@/services/paper/buildHtml';
import router from '@/router';
import store from '@/store';

export default ($opts) => {
	return new Promise((resolve) => {
		// console.log('PS_SaveDocument');
	
		// set defaults
		const _$opts = $opts || {};
		const _isAutoSave = (_has(_$opts, 'isAutoSave')) ? _$opts.isAutoSave : false;
		const _isMetaDataChanges = (_has(_$opts, 'isMetaDataChanges')) ? _$opts.isMetaDataChanges : false;
		const _isStealthSave = (_has(_$opts, 'isStealthSave')) ? _$opts.isStealthSave : false;
		const _updateMetaTags = (_has(_$opts, 'updateMetaTags')) ? _$opts.updateMetaTags : [];
		const _message = (_has(_$opts, 'message')) ? _$opts.message : '';

		// write the initial request local logs
		store.dispatch('logs/write', {
			message: _message,
		});

		// stop the inactivy timer
		store.dispatch('paperEdit/inactivityAlert/endTimer');

		// cancel the local version check in
		store.commit('paperEdit/localVersion/USER_TYPE_LOCAL_TIMER_CANCEL');

		if(!_isAutoSave && !_isStealthSave){
			// show loading if not Auto or Stealth
			store.commit('loaders/ADD_ID', 'App');
		}

		// prepare the document and try to save it
		PS_BuildHtml({
			updateMetaTags: _updateMetaTags,
		}).then((buildHtmlResponseObj)=>{
			// console.log('buildHtmlResponseObj.returnHtml');
			// console.log(buildHtmlResponseObj.returnHtml);

			let compressedPaperContent = LzString.compressToUTF16(buildHtmlResponseObj.returnHtml)
			let encryptedPaper = encodeURIComponent(compressedPaperContent);

			let params = {};
			
			if(_isMetaDataChanges){
				params['metaDataChanges'] = true;
			}

			// MESSAGES API - https://trello.com/c/3A5FYXAY/305-action-descriptions-for-paper-saves-in-online-add-in
			if(_message != ''){
				params['message'] = _message;
			}

			// start the inactivityAlert timer back up (but not in Outline)
			if(router.currentRoute.meta.tab === config.enums.Tab.EDITOR){
				store.dispatch('paperEdit/inactivityAlert/startTimer');
			}

			if(!_isAutoSave && !_isStealthSave){
				store.commit('loaders/ADD_ID', 'App');
			}

			/*
			to simulate vairious error responses replace one of these with one of the parameters in the api call:
				?						400
				url: 'v3/PaperX',		404
				url: 'v3/Paper/%',		?
				body: 'content=XXX',	?
			*/
			
			ApiS_MakeCall({
				contentType: 'application/x-www-form-urlencoded',
				body: 'content=' + encryptedPaper,
				method: 'PUT',
				params,
				responseType: 'json',
				url: 'v3/Paper',
			}).then((responseData)=>{
				// console.log('responseData');
				// console.log(responseData);

				store.commit('paperEdit/SET_IS_PAPER_UNSAVED', false);

				store.dispatch('paperEdit/toc/generateContent').then(()=>{

					// reset all ck instances so they are clean
					_forEach(CKEDITOR.instances, (ckInstance)=>{
						ckInstance.resetDirty();
					});
					
					if(!_isAutoSave && !_isStealthSave){
						// hide loading if not Auto or Stealth
						store.commit('loaders/REMOVE_ID', 'App');
					}
					

					if(responseData && _has(responseData, 's3VersionID')){
						// save was successful at this point, i can update the proper indexedDb item
						const openRequest = indexedDB.open('po_papers', 2);

						openRequest.onupgradeneeded = () => {
							// console.log('indb:onupgradeneeded po_papers 2');

							let db = openRequest.result;

							if(!db.objectStoreNames.contains('papers')){
								db.createObjectStore('papers', {
									autoIncrement: true,	// people online says this will fix an error i'm seeing in rollbar - i can't replicate to error to verify, changing this doesn't seem to matter locally
									keyPath: 'paperId',
								});
							}
						};//e:onupgradeneeded

						openRequest.onsuccess = () => {
							let db = openRequest.result;

							if(db.objectStoreNames.contains('papers')){

								const txn = db.transaction(["papers"], 'readwrite');
								const store = txn.objectStore('papers');

								let request = store.openCursor(buildHtmlResponseObj.paperId);

								request.onsuccess = () => {
									let requestResult = request.result;
									if (requestResult) {
										// this item should now be fresh since the save went through
										let item = requestResult.value;
										item.content = compressedPaperContent;
										item.isFresh = true;
										store.put(item);
									}
								};//e:onsuccess
									
								txn.oncomplete = () => {
									db.close();
								};
							}

						};//e:onsuccess
						
						// verify the last request in the log, it was successfull
						store.dispatch('logs/verify', {
							s3Link: responseData.s3VersionID
						});

						window.$vm.emitter.emit('referencesInPaperReady');	// emit to ReferenceCitation.vue (if that drawer isn't open then nothing will happen)

						return resolve({
							'contentUrl': (_has(responseData, 'contentUrl')) ? responseData.contentUrl : '',
							'pageCount': (_has(responseData, 'pageCount')) ? responseData.pageCount : '',
						});
						

					}//e:if:responseData

				});//e:dispatch:paperEdit/toc/generateContent

			});//e:ApiS_MakeCall
		});//e:PS_BuildHtml
	});//e:Promise
}