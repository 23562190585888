// PCKS_OnChange

import store from '@/store';

export default ($opts) => {
	// console.log('PCKS_OnChange');

	return new Promise((resolve) => {
		if(store.state.paperEdit.isPaperFooterOn) {
			// set a stop type timer with every change event
			store.commit('paperEdit/ck/USER_TYPE_FOOTER_TIMER_START', $opts.editorName);
		}
		store.commit('paperEdit/localVersion/USER_TYPE_LOCAL_TIMER_START');

		store.dispatch('paperEdit/inactivityAlert/startTimer');
		
		return resolve();
	});//e:Promise			

}
